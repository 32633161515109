import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
class SearchPipe {
  transform(items, searchText) {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.toLowerCase().indexOf(searchText) > -1;
    });
  }
  static {
    this.ɵfac = function SearchPipe_Factory(t) {
      return new (t || SearchPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "search",
      type: SearchPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchPipe, [{
    type: Pipe,
    args: [{
      name: 'search'
    }]
  }], null, null);
})();

/*
 *      Example use
 *      Basic Array of single type: *ngFor="let todo of todoService.todos | orderBy : '-'"
 *      Multidimensional Array Sort on single column: *ngFor="let todo of todoService.todos | orderBy : ['-status']"
 *      Multidimensional Array Sort on multiple columns: *ngFor="let todo of todoService.todos | orderBy : ['status', '-title']"
 */
class SortByLengthPipe {
  transform(arr, key) {
    if (!arr || !Array.isArray(arr) || !arr.every(obj => Array.isArray(obj[key]))) {
      return arr;
    } else {
      return arr.sort((a, b) => b[key].length - a[key].length);
    }
  }
  static {
    this.ɵfac = function SortByLengthPipe_Factory(t) {
      return new (t || SortByLengthPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "sortByLength",
      type: SortByLengthPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SortByLengthPipe, [{
    type: Pipe,
    args: [{
      name: 'sortByLength'
    }]
  }], null, null);
})();

/*
 *      Example use
 *      Basic Array of single type: *ngFor="let todo of todoService.todos | orderBy : '-'"
 *      Multidimensional Array Sort on single column: *ngFor="let todo of todoService.todos | orderBy : ['-status']"
 *      Multidimensional Array Sort on multiple columns: *ngFor="let todo of todoService.todos | orderBy : ['status', '-title']"
 */
class SortByPipe {
  constructor() {
    this.value = [];
  }
  static _orderByComparator(a, b) {
    if (a === null || typeof a === 'undefined') {
      a = 0;
    }
    if (b === null || typeof b === 'undefined') {
      b = 0;
    }
    if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
      // Isn"t a number so lowercase the string to properly compare
      a = a.toString();
      b = b.toString();
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }
    return 0; // equal each other
  }

  transform(input, config = '+') {
    if (!input) {
      return input;
    }
    // make a copy of the input"s reference
    this.value = [...input];
    let value = this.value;
    if (!Array.isArray(value)) {
      return value;
    }
    if (!Array.isArray(config) || Array.isArray(config) && config.length === 1) {
      let propertyToCheck = !Array.isArray(config) ? config : config[0];
      let desc = propertyToCheck.substr(0, 1) === '-';
      // Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        return !desc ? value.sort() : value.sort().reverse();
      } else {
        let property = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-' ? propertyToCheck.substr(1) : propertyToCheck;
        return value.sort(function (a, b) {
          let aValue = a[property];
          let bValue = b[property];
          let propertySplit = property.split('.');
          if (typeof aValue === 'undefined' && typeof bValue === 'undefined' && propertySplit.length > 1) {
            aValue = a;
            bValue = b;
            for (let j = 0; j < propertySplit.length; j++) {
              aValue = aValue[propertySplit[j]];
              bValue = bValue[propertySplit[j]];
            }
          }
          return !desc ? SortByPipe._orderByComparator(aValue, bValue) : -SortByPipe._orderByComparator(aValue, bValue);
        });
      }
    } else {
      // Loop over property of the array in order and sort
      return value.sort(function (a, b) {
        for (let i = 0; i < config.length; i++) {
          let desc = config[i].substr(0, 1) === '-';
          let property = config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-' ? config[i].substr(1) : config[i];
          let aValue = a[property];
          let bValue = b[property];
          let propertySplit = property.split('.');
          if (typeof aValue === 'undefined' && typeof bValue === 'undefined' && propertySplit.length > 1) {
            aValue = a;
            bValue = b;
            for (let j = 0; j < propertySplit.length; j++) {
              aValue = aValue[propertySplit[j]];
              bValue = bValue[propertySplit[j]];
            }
          }
          let comparison = !desc ? SortByPipe._orderByComparator(aValue, bValue) : -SortByPipe._orderByComparator(aValue, bValue);
          // Don"t return 0 yet in case of needing to sort by next property
          if (comparison !== 0) {
            return comparison;
          }
        }
        return 0; // equal each other
      });
    }
  }

  static {
    this.ɵfac = function SortByPipe_Factory(t) {
      return new (t || SortByPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "sortBy",
      type: SortByPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SortByPipe, [{
    type: Pipe,
    args: [{
      name: 'sortBy'
    }]
  }], null, null);
})();
class StringJoinPipe {
  transform(value, field, separator = ', ') {
    return value.map(a => a[field]).join(separator);
  }
  static {
    this.ɵfac = function StringJoinPipe_Factory(t) {
      return new (t || StringJoinPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "stringJoin",
      type: StringJoinPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StringJoinPipe, [{
    type: Pipe,
    args: [{
      name: 'stringJoin'
    }]
  }], null, null);
})();
const pipes = [SearchPipe, SortByLengthPipe, SortByPipe, StringJoinPipe];
class ArrayUtilityModule {
  static {
    this.ɵfac = function ArrayUtilityModule_Factory(t) {
      return new (t || ArrayUtilityModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ArrayUtilityModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArrayUtilityModule, [{
    type: NgModule,
    args: [{
      declarations: [...pipes],
      exports: [...pipes],
      imports: [CommonModule]
    }]
  }], null, null);
})();
function ToEntities(a, key, initialEntities) {
  return a.reduce((e, obj) => {
    return {
      ...e,
      [obj[key]]: obj
    };
  }, {
    ...initialEntities
  });
}

/**
 * Generated bundle index. Do not edit.
 */

export { ArrayUtilityModule, SearchPipe, SortByLengthPipe, SortByPipe, StringJoinPipe, ToEntities };
